import React from 'react';
import logoWorldBank from '../assets/images/logos/theworldbank_r.png';
import logoMitQuest from '../assets/images/logos/quest_mit_r.png';
import logoMitMediaLab from '../assets/images/logos/mit_medialab_r.png';
import logoConnectionScience from '../assets/images/logos/connection_science_r.png';
import logoGrandata from '../assets/images/logos/grandata_transparent.png';
import logoUSBank from '../assets/images/logos/us_bank.png';
import logoFoursquare from '../assets/images/logos/Foursquare_logo.png';
import logoBBVA from '../assets/images/logos/bbva.png';
import logoZendesk from '../assets/images/logos/zendesk_logo.png';
import logoWomply from '../assets/images/logos/womply.png';

const Footer = () => (
  <footer id="footer">
    <div className="inner">
      <ul className="logos">

        <li>
          <img src={logoMitMediaLab} alt="MIT Media Lab Logo" className="image fit" />
        </li>
        <li>
          <img src={logoWorldBank} alt="The World Bank Logo" className="image fit" />
        </li>
        <li>
          <img src={logoGrandata} alt="GranData" className="image fit" />
        </li>
        <li>
          <img
            src={logoConnectionScience}
            alt="MIT Connection Science Logo"
            className="image fit"
          />
        </li>
        <li>
          <img src={logoUSBank} alt="US Bank" className="image fit" />
        </li>

        <li>
          <img src={logoFoursquare} alt="Foursqure" className="image fit" />
        </li>

        <li>
          <img src={logoBBVA} alt="BBVA" className="image fit" />
        </li>

        <li>
          <img src={logoZendesk} alt="Zendesk" className="image fit" />
        </li>

        <li>
          <img src={logoWomply} alt="womply" className="image fit" />
        </li>

      </ul>

      {/* <ul className="copyright">
                <li>Design: <a href="https://html5up.net">HTML5 UP</a></li>
            </ul> */}
    </div>
  </footer>
);

export default Footer;
